export default class VideoMixer {
    constructor(useOffscreenCanvas = false) {
        this.canvas = useOffscreenCanvas && typeof OffscreenCanvas !== 'undefined'
            ? new OffscreenCanvas(1280, 720)
            : document.createElement('canvas');
        this.tracks = new Map();
        this.changeSizeCanvas();
        this.loadingImages();
        this.intervalId = null;
        this.currentSpeakers = null;
        this.backgroundCanvas = null;
    }

    addTrack(id, userId, track) {
        if (this.tracks.has(id)) {
            console.log(`Track with ID ${id} already exists. Use replaceTrack instead.`);
        } else { 
            const videoElement = document.getElementById('videoPlayer_' + id);
            console.log(track);
            this.tracks.set(id, { userId, videoElement });
            //this.changeSizeCanvas();
            this.startRendering();
        }
    }

    removeTrack(id) {
        if (!this.tracks.has(id)) {
            console.log(`No track with ID ${id} to remove.`);
        } else {
            this.tracks.delete(id);
            //this.changeSizeCanvas();
            if (this.tracks.size === 0) {
                this.stopRendering();
            }
        }
    }

    removeTracksExcept(ids) {
        const missingIds = Array.from(this.tracks.keys()).filter(id => !ids.includes(id));
        missingIds.forEach((id) => {
            this.removeTrack(id);
        });
    }

    changeSizeCanvas() {
        var length = this.tracks ? this.tracks.size : 0;
        console.log('Change size canvas: ', length);
        this.canvas.width = 1280;
        this.canvas.height = 720;
        // if(length <= 1) {
        //     this.canvas.width = 1098;
        //     this.canvas.height = 618;
        // } else if (length > 1) {
        //     this.canvas.width = 1098;
        //     this.canvas.height = 618 / length;
        // }
        console.log('Canvas size (W x H): ', this.canvas.width + 'x' + this.canvas.height);
        this.context = this.canvas.getContext('2d');
        this.outputStream = this.canvas.captureStream(30);
    }

    loadingImages() {
        //Watermark logo
        this.isLogoImageReady = false;
        this.logoImage = new Image();
        this.logoImage.src = '/logo-light-small.png';
        this.logoImage.onload = () => {
            console.log('Logo image is ready to draw.');
            this.isLogoImageReady = true;
        };

        //Background
        this.isBgImageReady = false;
        this.bgImage = new Image();
        this.bgImage.src = '/stream-bg.jpg';
        this.bgImage.onload = () => {
            console.log('BG image is ready to draw.');
            this.isBgImageReady = true;
        };
    }

    getMixedVideoTrack() {
        console.log('Video render video tracks: ', this.outputStream.getVideoTracks());
        return this.outputStream.getVideoTracks()[0];
    }

    startRendering() {
        if (this.intervalId) 
            return;
            
        console.log('Go render!');
        const render = () => {
            if (!this.context || this.tracks.size === 0) 
                return;

            const cellWidth = this.canvas.width / this.tracks.size;
            const cellHeight = this.canvas.height / this.tracks.size;
            
            //Clear
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            
            //Background
            this.drawBackground();
                
            //Active speaker            
            this.context.strokeStyle = "#6691E7";
            this.context.lineWidth = 4;
 
            let i = 0;
            for (const { userId, videoElement } of this.tracks.values()) {
                const x = i;
                const y = this.tracks.size > 1 
                    ? (this.canvas.height - cellHeight) / 2 
                    : 0;
                //this.context.save();
                //this.context.scale(-1, 1);
                //this.context.drawImage(videoElement, -1 * x, y, -cellWidth, cellHeight);
                this.context.drawImage(videoElement, x, y, cellWidth, cellHeight);
                //this.context.restore();
                if(this.currentSpeakers && this.currentSpeakers.includes(userId)) {
                    this.context.strokeRect(x, y, cellWidth, cellHeight - 1);
                }
                i += cellWidth;
            }

            if (this.isLogoImageReady) {
                this.context.drawImage(this.logoImage, this.canvas.width - 151, this.canvas.height - 36, 151, 36);
            }
        };

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.intervalId = setInterval(render, 1000 / 30); // 30 FPS
    }

    drawBackground() {
        if (this.isBgImageReady && this.bgImage.complete && this.tracks.size > 1) {
            this.context.drawImage(this.bgImage, 0, 0, this.canvas.width, this.canvas.height);
        }
    }

    stopRendering() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if (this.context) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    }

    currentSpeaker(list) {
        this.currentSpeakers = list;
    }

    clean() {
        this.tracks = new Map();
        //this.changeSizeCanvas();
    }
}
