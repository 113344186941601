export default class streamPublisher {

  constructor(options)
	{
    let defaults = {
      whipUrl:      'http://localhost:8889/busycast/whip',
      logLevel:     'error',
      videoElement: null,
      videoBandwidth: 2000,
      width: 640,
      height: 480,
      videoRequired: true,
      audioRequired: true,

      onConnectionStateChange: null,
      onPublisherCreated: null,
      onOffer: null,
      onAnswer: null,
      onConnectionError: null,
      videoSelect: null,
    };

    // Merge defaults and options, without modifying defaults
    this.settings = Object.assign({}, defaults, options);

    //this.logger.info('settings:', this.settings);
    this.callback('onPublisherCreated', this.settings);

    this.ac = new AudioContext();
    this.destination = new MediaStreamAudioDestinationNode(this.ac);

    if ("undefined" != typeof window){
      window.webRTCjsInstance = this; // Firefox GC workaround
    }
	}

  callback(cbName, cbPayload) {
    if (typeof this.settings[cbName] === "function") {
      this.settings[cbName].apply( this, [cbPayload] );
    }
  }

  async setStream(stream, videoTrack) {
    const mergedStream = this.destination.stream;
    
    //Audio
    stream.getAudioTracks().forEach(track => {
      const source = this.ac.createMediaStreamSource(new MediaStream([track]));
      source.connect(this.destination);
    });

    //Video
    if(this.settings.videoRequired) {
      console.log('Video is required!');
      //var videoTrack = stream.getVideoTracks()[0];
      if(videoTrack) {
        console.log('Video track here: ', videoTrack);
        console.log("mergedAudioStream: ", mergedStream);
        mergedStream.addTrack(videoTrack);
      }
    }

    this.s = mergedStream;
  }

  async addTrack(track) {
    console.log("Add track to Stream Publisher", track);
    
    if(track.kind === 'audio') {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(track);
      const source = this.ac.createMediaStreamSource(mediaStream);
      source.connect(this.destination);
    }
  }

  async removeAudioTrack(track) {
    console.log("Remove audio track from Stream Publisher", track);

    if (this.s.getAudioTracks().includes(track)) {
      track.stop();
      this.s.removeTrack(track);

      const sender = this.pc.getSenders().find(s => s.track === track);
      if (sender) {
          this.pc.removeTrack(sender);
          console.log('Track removed:', track);
      } else {
          console.log('Track not found in connection.');
      }
      
      // const source = this.ac.createMediaStreamSource(this.destination.stream);
      // source.disconnect();
      // source.connect(this.destination);
    } else {
      console.warn("Track not found in the audio stream");
    }
  }

  sortByMimeTypes(codecs, preferredOrder) {
    return codecs.sort((a, b) => {
      const indexA = preferredOrder.indexOf(a.mimeType);
      const indexB = preferredOrder.indexOf(b.mimeType);
      const orderA = indexA >= 0 ? indexA : Number.MAX_VALUE;
      const orderB = indexB >= 0 ? indexB : Number.MAX_VALUE;
      return orderA - orderB;
    });
  }

  async publish() {

    this.pc = new RTCPeerConnection();

    if (this.pc.connectionState != undefined) {
        this.pc.onconnectionstatechange = () => {
          switch (this.pc.connectionState)
          {
            default:
              this.callback('onConnectionStateChange', this.pc.connectionState);
              //console.log(event);
              break;
          }
        }
    } else {
        this.pc.oniceconnectionstatechange = () => {
          this.callback('onIceconnectionStateChange', this.pc.iceConnectionState);
          //console.log(event);
        };

    }

    this.pc.addStream(this.s);
    console.log('Stream was added to Publisher: ', this.s.getTracks());

    //Set codecs
    const supportedCodecs = RTCRtpReceiver.getCapabilities("video");
    console.log('List of codecs availReceiveCodecs: ', supportedCodecs);
    const transceivers = this.pc.getTransceivers();
    console.log('getTransceivers list: ', transceivers);
    transceivers.forEach(transceiver => {
      if (transceiver.sender.track && transceiver.sender.track.kind === "video") {
        
        // const h264Codecs = availReceiveCodecs.codecs.filter(codec => codec.mimeType === "video/H264");
        // if (h264Codecs) {
        //   console.log('Set codec h264: ', [h264Codecs]);
        //   transceiver.setCodecPreferences(h264Codecs);
        // }
        const preferredCodecs = ["video/H264", "video/VP9", "video/VP8"];
        const sortedCodecs = this.sortByMimeTypes(supportedCodecs.codecs, preferredCodecs);
        console.log('Sorted codecs: ', sortedCodecs);
        transceiver.setCodecPreferences(sortedCodecs);
      }
    });

    //Create SDP offer
    const offer = await this.pc.createOffer();

    this.callback('onOffer', offer.sdp);

    await this.pc.setLocalDescription(offer)

    let fetched;
    try {
      //Do the post request to the WHIP endpoint with the SDP offer
      fetched = await fetch (this.settings.whipUrl, {
            method : "POST",
            body: offer.sdp,
            headers:{ "Content-Type" : "application/sdp"},
            keepalive: true
      });
      if (!fetched.ok) {
        this.callback('onConnectionError', 'Connection error ' + fetched.status);
        return;
      }
    } catch (error) {
      this.callback('onConnectionError', 'Connection error');
      console.error(error);
    }

    if (fetched.headers.get("location")) {
      this.location = new URL(fetched.headers.get("location"), this.settings.whipUrl);
    }

    //Get the SDP answer
    const answer = await fetched.text();
    this.callback('onAnswer', answer);

    await this.pc.setRemoteDescription ({type:"answer", sdp:answer});

  }

  async stop()
  {
    if (!this.pc) {
      // Already stopped
      return
    }

    if (this.location) {
      let fetched;
      try {
        //Send a delete
        fetched = await fetch(this.location, {
          method: "DELETE",
          keepalive: true
        });

        if (!fetched.ok) {
          this.callback('onConnectionError', 'failed to delete session ' + fetched.status);
          return;
        }
        else
            console.log("WHIP resource was deleted.");
      } catch (error) {
        this.callback('onConnectionError', 'Connection error ' + error);
      }
      this.callback('onConnectionStateChange', 'session deleted');
    }

    await new Promise(r => setTimeout(r, 200));
    this.pc.close();
    this.pc = null;

    this.callback('onConnectionStateChange', 'disconnected');
  }
}
