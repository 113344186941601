<script>
import axios from 'axios';
import * as signalR from "@microsoft/signalr";
import { setupConnection, linkify } from "./js/from-server";
import connectionManager from "./js/connections";
import mappingManager from "./js/mappings";
import streamPublisher from "./js/stream-publisher";
import VideoMixer from './js/render-video.js';
import Recorder from "../recorder/Recorder.vue";
import Uploader from "../uploader/Uploader.vue";
import { write } from 'clipboardy';

var wsconn = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_SIGNALR_URL, signalR.HttpTransportType.WebSockets)
  .configureLogging(signalR.LogLevel.None).build();

import Profiles from "./_Profiles2.vue";
import Messages from "./_Messages.vue";
import Title from "./_Title.vue";
import Donate from "./_Donate.vue";

export default {
  setup() {
    return {
    };
  },
  data() {
    return {
      debugMode: false,
      isServerSide: false,
      idSessionGroup: null,
      wsconnSetup: false,
      hasMediaAccess: false,
      myConnectionId: null,
      moderatorConnectionId: null,
      connections: [],
      connectionConfig: null,
      tracksOrder: [],
      groupStream: null,
      myAudioTrack: null,
      myVideoTrack: null,
      pinnedMessage: null,
      listenersCounter: 0,
      newMessageCounter: 0,
      speakersCounter: 0,
      makingOffer: false,

      //Recorder
      ac: null,
      destination: null,
      visibleConnection: false,
      visibleModeratorsToolset: false,
      visibleMembersToolset: false,
      hasCallProcess: false,
      hasRecord: false,
      recorderState: 'off',

      //Stream
      streamSharing: false,
      streamSharingPublisher: null,
      streamSharingId: null,
      streamSharingUrl: null,
      streamSharingWhip: null,
      streamConfigAllowChat: true,
      streamConfigAllowDonations: false,
      videoMixerRenderer: null,

      //Post create
      buttonCreateDisabled: false,
      buttonCreateText: 'Upload',
      title: '',
      message: '',

      //Episode data
      episode: {
        type: Object,
        default: function () {
          return {
            id: '',
            title: '',
            short: '',
            urlAvatar: '',
          }
        }
      }
    };
  },
  components: {
    Profiles,
    Messages,
    Title,
    Recorder,
    Donate,
    Uploader,
  },
  methods: {
    accessMedia(serverSide) {
      if(this.myAudioTrack !== null)
        return;
      this.isServerSide = serverSide;
      this.$refs.postRecorder.accessToMedia();
    },
    readyStreamAccess(stream) {
      if(this.visibleConnection) {
        console.log("Change stream");
        this.replaceTrack(stream);
      } else {
        console.log('Ready stream');
        this.hasMediaAccess = true;
        this.myAudioTrack = stream.getAudioTracks()[0];
        this.myVideoTrack = stream.getVideoTracks()[0];
        console.log("Ready to connect...");
      }
    },
    replaceTrack(stream) {
      console.log('Remove/stop old audio track');
      var trackAudioEnabled = this.myAudioTrack.enabled;
      var trackVideoEnabled = this.myVideoTrack.enabled;
      
      if(this.myAudioTrack) {
        this.myAudioTrack.stop();
        this.groupStream.removeTrack(this.myAudioTrack);
        connectionManager.removeTrack(this.myConnectionId, this.myAudioTrack);
        
        if(this.streamSharing) {
          this.streamSharingPublisher.removeAudioTrack(this.myAudioTrack);
        }
      }
      
      console.log('Remove/stop old video track');
      if(this.myVideoTrack) {
        this.myVideoTrack.stop();
        this.groupStream.removeTrack(this.myVideoTrack);
        connectionManager.removeTrack(this.myConnectionId, this.myVideoTrack);
      }
      
      var newAudioTrack = stream.getAudioTracks()[0];
      var newVideoTrack = stream.getVideoTracks()[0];
      console.log('Setup new audio/video tracks');
      for (const [key, value] of Object.entries(this.connections)) {
        console.log('Change tracks for connectionId: ' + key);
        const senderAudio = value.getSenders().find(s => s.track === this.myAudioTrack && s.track.kind === this.myAudioTrack.kind);
        if(senderAudio) {
          senderAudio.replaceTrack(newAudioTrack);
        }
        
        const senderVideo = value.getSenders().find(s => s.track === this.myVideoTrack && s.track.kind === this.myVideoTrack.kind);
        if(senderVideo) {
          senderVideo.replaceTrack(newVideoTrack);
        }
      }
      
      this.myAudioTrack = newAudioTrack;
      this.myAudioTrack.enabled = trackAudioEnabled;
      this.groupStream.addTrack(this.myAudioTrack);
      connectionManager.addTrack(this.myConnectionId, this.myAudioTrack);
      
      this.myVideoTrack = newVideoTrack;
      this.myVideoTrack.enabled = trackVideoEnabled;
      connectionManager.addTrack(this.myConnectionId, this.myVideoTrack);
      this.groupStream.addTrack(this.myVideoTrack);
      this.setSourceOfTrack(this.myVideoTrack, this.myConnectionId);

      if(this.streamSharing) {
        this.streamSharingPublisher.addTrack(this.myAudioTrack);
        //this.videoMixerRenderer.replaceTrack(this.myConnectionId, this.myVideoTrack);
      }
    },
    readyRecordFile(state) {
      console.log("Recorder new state: ", state);
      this.hasRecord = state;
    },
    initiateOffer(connectionId) {
      console.log("Init Offer...");
      var connection = this.getConnection(connectionId);

      connection.createOffer({ offerToReceiveAudio : true, iceRestart: false })
      .then(offer => {
          console.log('WebRTC: Created Offer. Description after offer:');
          console.log(offer);
          connection.setLocalDescription(offer)
          .then(() => {
            console.log('WebRTC: set Local Description: ');
            console.log('connection before sending offer ', connection);
              setTimeout(() => {
                  this.sendHubSignal(JSON.stringify({ "sdp": connection.localDescription }), connectionId);
              }, 1000);
          })
          .catch(err => console.error('WebRTC: Error while setting local description', err));
      }).catch(err => console.error('WebRTC: Error while creating offer', err));
    },
    getConnection(connectionId) {
      if (this.connections[connectionId]) {
        console.log("WebRTC: connections partner client exist");
        return this.connections[connectionId];
      }
      else {
        console.log("WebRTC: initialize new connection. My side: " + this.isServerSide);
        var connection = this.createPeerConnection(connectionId);
        this.connections[connectionId] = connection;
        return connection;
      }
    },

    //Peer connection
    getConnectionConfig() {
      console.log('Loading TURN config');
      if(this.connectionConfig === null) {
        var vm = this;
        axios.get('/api/config/get-turn', { withCredentials: true })
          .then((response) => {
            console.log("Config data: ", response.data);
            var peerConnectionConfig = { 
              iceServers: response.data,
            };
            vm.connectionConfig = peerConnectionConfig;
          })
          .catch(function () {
          });
      }
    },
    createPeerConnection(connectionId) {
      console.log("Peer connection config", this.connectionConfig);
      var connection = new RTCPeerConnection(this.connectionConfig);

      console.log("Add tracks to new connection: ", connectionId);
      if (this.isServerSide) {
        console.log("Add groupStream tracks to new connection: ", connectionId);

        //var tracksMetaData = [];
        var liveActiveStream = this.groupStream.getTracks().filter(track => track.readyState === "live");
        liveActiveStream.forEach((track) => {
            var userId = mappingManager.getUserIdByConnectionId(connectionId);
            var connectionIds = mappingManager.getConnectionIdsByUserId(userId);
            var cid = connectionManager.getConnectionIdByTrack(track);
            console.log('Found cid: ', cid);
            console.log('ConnectionIds: ', connectionIds);
            if(cid !== '' && !connectionIds.includes(cid)) {
              console.log("Add track to connection: ", track);
              connection.addTrack(track);
            }
        });
      } else {
        console.log("Add myTrack to new connection.");
        connection.addTrack(this.myAudioTrack);
        connection.addTrack(this.myVideoTrack);
      }

      connection.onicecandidate = (event) => this.callbackIceCandidate(event, connection, connectionId);
      connection.oniceconnectionstatechange = (event) => this.callbackIceConnectionStateChanged(event, connection, connectionId);
      connection.onremovetrack = (event) => this.callbackRemoveTrack(connection, event);
      connection.onnegotiationneeded = (event) => this.callbackNegotiationNeeded(event, connection, connectionId);
      connection.ontrack = (event) => this.callbackTrack(event, connection, connectionId);

      return connection;
    },
    sendCurrentTracksOrder(connection, connectionId) {
      var tracksMetaData = [];

      const transceivers = connection.getTransceivers();
      transceivers.forEach((t) => {
        var track = t.sender.track;
        if(track) {
          var cid = connectionManager.getConnectionIdByTrack(track);
          var userId = mappingManager.getUserIdByConnectionId(cid);
          console.log("Transceiver Mid: ", t.mid);
          tracksMetaData.push({
            connectionId: cid,
            userId: userId,
            mid: t.mid,
            kind: track.kind
          });
        }
      });

      if(tracksMetaData.length > 0) {
        var data = { 
          tracks: tracksMetaData,
          connectionId: connectionId
        };
        wsconn.invoke("SendTracksOrder", data);
        console.log("sendCurrentTracksOrder", data);
      }
    },
    callbackIceCandidate (evt, connection, partnerClientId) {
      console.log("WebRTC: Ice Candidate callback");

      if (evt.candidate) {
          // Found a new candidate
          console.log('WebRTC: new ICE candidate');
          this.sendHubSignal(JSON.stringify({ "candidate": evt.candidate }), partnerClientId);
      } else {
          // Null candidate means we are done collecting candidates.
          console.log('WebRTC: ICE candidate gathering complete');
          this.sendHubSignal(JSON.stringify({ "candidate": null }), partnerClientId);
      }
    },
    callbackIceConnectionStateChanged(event, connection, connectionId) {
      var cid = connectionId;
      if(cid === '' || !this.connections[cid]) {
        console.log('Unknown connectionId, iceConnectionState:', connection.iceConnectionState);
        return;
      }
      console.log('ICE Connection state changed [' + cid  + ']:', connection.iceConnectionState);

      //Connected
      if(connection.iceConnectionState === 'connected') {
        if(this.isServerSide) {
          this.applyToOldFromNew(connection, connectionId);
        } else {
          wsconn.invoke("CallSuccess");
        }
      }

      //Disconnected
      if(connection.iceConnectionState === 'disconnected') {
        //
      }

      //Failed
      if(connection.iceConnectionState === 'failed') {
        //connection.restartIce();
        if(this.isServerSide) {
          //Remove track from other connections
          if(connectionManager.hasConnectionId(cid)) {
            console.log("Remove track from Connections: ", cid);

            var tracks = connectionManager.getTracksByConnectionId(cid);
            tracks.forEach((item) => {
              if(this.connections[item.cid])
              {
                console.log("Remove track from groupStream: ", item.cid);
                console.log(this.groupStream.getTracks());
                this.groupStream.removeTrack(item.track);
                console.log(this.groupStream.getTracks());

                console.log("Remove track from connectionManager: ", item.cid);
                connectionManager.removeTrack(item.cid, item.track);
                
                //From other connections
                for (const [key, value] of Object.entries(this.connections)) {
                  if (key !== item.cid) {
                    console.log("Remove track from connection: ", item.cid);
                    const sender = value.getSenders().find(s => s.track === item.track);
                    if (sender) {
                        value.removeTrack(sender);
                        console.log('Track removed:', sender.track);
                    } else {
                        console.log('Track not found in connection.');
                    }
                  }
                }

                //Renderer
                this.videoMixerRenderer.removeTrack(item.cid);
              }

            });

          }

          if(this.connections[cid]) {
            console.log("Remove connection:", cid);
            delete this.connections[cid];
          }

          console.log("Trying to kick user:", cid);
          wsconn.invoke("Kick", cid);
        }
        else {
          this.clearAllConnects();
        }
      }

    },
    callbackNegotiationNeeded (event, connection, connectionId) {
      if(!this.connections[connectionId])
        return;
      console.log("Negotiation needed with [" + connectionId +  "]: ", event);

      try {
        this.makingOffer = true;
        for (const [key, value] of Object.entries(this.connections)) {
          if (value === event.target && key !== this.moderatorConnectionId) {
            this.initiateOffer(key);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.makingOffer = false;
      }
    },
    callbackRemoveTrack(connection, event) {
      console.log("Remove track", event);
    },
    callbackTrack(event, connection, connectionId) {
      console.log("Add new audio track TAG", event);

      var cid;
      var track = event.track;
      if(this.isServerSide)
        cid = connectionId;
      else
        cid = track.id;
      console.log("Tag cid: ", cid);
      console.log("Track: ", track);

      //Group stream
      this.groupStream.addTrack(track);
      if(this.isServerSide) {

        this.setSourceOfTrack(track, connectionId);
        connectionManager.addTrack(cid, track);

        if(this.streamSharing) {
          this.streamSharingPublisher.addTrack(track);
          if(track.kind === 'video') {
            var userId = mappingManager.getUserIdByConnectionId(connectionId);
            this.videoMixerRenderer.addTrack(connectionId, userId, track);
          }
        }
      }

      //Add to current recording session
      if(this.ac != null && this.destination != null && track.kind === 'audio') {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(this.ac, { mediaStream: mediaStream });
        mediaStreamAudioSourceNode.connect(this.destination);
      }

      console.log("Track has been added TAG", track);
    },
    setSourceOfTrackByMid(mid, connectionId) {
      const transceiver = this.connections[this.moderatorConnectionId].getTransceivers().find(t => t.mid === mid);
      console.log('Hehehehe', transceiver);
      if (transceiver && transceiver.receiver) {
        console.log('Gogogogo', transceiver);
        const track = transceiver.receiver.track;
        this.setSourceOfTrack(track, connectionId);
      }
    },
    setSourceOfTrack(track, connectionId, forceReset = true) {
      if(track.kind === 'audio') {
        console.log('Setup audio track from: ', connectionId);
        const audioElement = document.getElementById('audioPlayer_' + connectionId);
        if(audioElement) {
          audioElement.srcObject = new MediaStream([track]);
          audioElement.autoplay = true;
        }
      }

      if(track.kind === 'video') {
        console.log('Setup video track from: ', connectionId);
        const videoElement = document.getElementById('videoPlayer_' + connectionId);
        if(videoElement) {
          if(forceReset || !videoElement.srcObject) {
            videoElement.srcObject = new MediaStream([track]);
            videoElement.autoplay = true;
          }
        }
      }
    },
    clearAllConnects() {
      console.log("Clear all connects, groupStream etc.")
      this.connections = [];
      this.tracksOrder = [];
      this.groupStream = new MediaStream();
      this.$refs.pnlProfiles.updateProfiles([]);
      this.updateProfiles([]);
      connectionManager.clearTracks();
      mappingManager.clearMappings();
    },
    applyToOldFromNew(connection, connectionId) {
      console.log("Trying to add new tracks to old connections from ConnectionId: " + connectionId + ". Receivers count: " + connection.getReceivers().length);
      
      var tracks = connectionManager.getTracksByConnectionId(connectionId);
      tracks.forEach((t) => {
        let track = t.track;
        console.log('New track for old connections: ', track);
        for (const [key, value] of Object.entries(this.connections)) {
          console.log("Key: ", key);
          if (key !== connectionId) {
              
              const trackExists = value.getSenders().some(sender => sender.track === track);
              if(!trackExists) {
                console.log("Added to old connection['" + key + "'] new track: ", track);
                value.addTrack(track);
              }
              else {
                console.log("Track was added before.");
              }

            }
        }
      });

      this.sendCurrentTracksOrder(connection, connectionId);
    },
    receivedSdpSignal (connection, partnerClientId, sdp) {
      var desc = new RTCSessionDescription(sdp);
      const offerCollision = desc.type === "offer" &&
        (this.makingOffer || connection.signalingState !== "stable");

      if (this.isServerSide && offerCollision) {
        console.log("Ignore receivedSdpSignal");
        return;
      }

      console.log('connection: ', connection);
      console.log('sdp: ', sdp);  
      console.log('WebRTC: called receivedSdpSignal && processing sdp signal');
      connection.setRemoteDescription(new RTCSessionDescription(desc))
      .then(() => {
          console.log('WebRTC: set Remote Description. Type: ' + connection.remoteDescription.type);
          if (connection.remoteDescription.type == "offer") {
              console.log('WebRTC: remote Description type offer');

              connection.createAnswer().then((desc) => {
                  console.log('WebRTC: create Answer...');
                  connection.setLocalDescription(desc)
                  .then(() => {
                      console.log('WebRTC: set Local Description...');
                      console.log('connection.localDescription: ', connection.localDescription);
                      //setTimeout(() => {
                        this.sendHubSignal(JSON.stringify({ "sdp": connection.localDescription }), partnerClientId);
                      //}, 0);
                  })
                  .catch(this.logError);
              }, this.logError);
          } else if (connection.remoteDescription.type == "answer") {
              console.log('WebRTC: remote Description type answer');
          }
      }).catch(this.logError);
    },
    receivedCandidateSignal(connection, partnerClientId, candidate) {
      console.log('WebRTC: adding full candidate');
      connection.addIceCandidate(new RTCIceCandidate(candidate), () => console.log("WebRTC: added candidate successfully"), () => console.log("WebRTC: cannot add candidate"));
    },

    //Invokes
    ifNeedWsconnSetup() {
      console.log("SignalR current state", wsconn.state);
      if(wsconn.state === 'Connected') {
        this.wsconnStop();
      }

      if(this.wsconnSetup === false) {
        console.log("Need to setup wsconn...");
        setupConnection(wsconn, this);
        this.wsconnSetup = true;
      }
    },
    wsconnStop() {
      setTimeout(() => {
        wsconn.stop().then(() => {
            console.log("SignalR stopped.");
          });
      }, 750);
    },
    createGroupStream() { 
      this.groupStream = new MediaStream();
      this.groupStream.addTrack(this.myAudioTrack);
      connectionManager.addTrack(this.myConnectionId, this.myAudioTrack);
      this.groupStream.addTrack(this.myVideoTrack);
      connectionManager.addTrack(this.myConnectionId, this.myVideoTrack);
      this.speakerMicStateChanged(this.myAudioTrack.enabled);
      this.speakerCamStateChanged(this.myVideoTrack.enabled);
    },
    createSession() {
      console.log('Creating session...');

      //One time setup
      this.ifNeedWsconnSetup();

      wsconn.start().then(() => {
        console.log("SignalR: Connected. My connectionId: ", wsconn.connectionId);
        this.myConnectionId = wsconn.connectionId;
        this.createGroupStream();

        var id = this.$route.params.id;
        wsconn.invoke("Create", id)
        .then(() => {
          console.log("Session created successfully.");
          this.$refs.pnlMessages.connected = true;
          this.visibleModeratorsToolset = true;
          this.visibleConnection = true;
          this.hasRecord = false;
        })
        .catch((error) => {
          console.error(error);
        });

      }).catch((error) => {
        console.error(error);
      });
    },
    stopSession() {
      if(this.streamSharing) {
        this.streamStopPublish();
      }
      this.stopAllConnections();
      this.connections = [];
      this.groupStream = null;
      this.visibleModeratorsToolset = false;
      this.visibleMembersToolset = false;
      this.visibleConnection = false;
      this.$refs.postRecorder.soundRecStop();
      this.ac = null;
      this.destination = null;
      this.clearAllConnects();
      this.streamSharingId = null;
      this.listenersCounter = 0;
      this.streamConfigAllowChat = true;
      this.streamConfigAllowDonations = false;
      this.wsconnStop();
    },
    joinSession() {
      console.log('Joining session...');

      //One time setup
      this.ifNeedWsconnSetup();

      wsconn.start().then(() => {
        console.log("SignalR: Connected. My connectionId: ", wsconn.connectionId);
        this.myConnectionId = wsconn.connectionId;
        this.createGroupStream();

        var id = this.$route.params.session;
        wsconn.invoke("Join", id)
        .then(() => {
          this.$refs.pnlMessages.connected = true;
          this.visibleMembersToolset = true;
          this.visibleConnection = true;
          this.idSessionGroup = id;
          this.callToModerator();
        })
        .catch((error) => {
          console.error(error);
        });

      }).catch((error) => {
        console.error(error);
      });
    },
    callToModerator () {
      wsconn.invoke('Call')
      .then(() => {
        this.hasCallProcess = true;
      })
      .catch((e) => {
        console.log(e);
      });
    },
    stopAllConnections () {
      if(this.connections) {
        for (const [key, value] of Object.entries(this.connections)) {
          if(key)
            value.close();
        }
      }
    },
    hangupCall () {
      if(wsconn)
        this.wsconnStop();
      this.stopAllConnections();
      this.connections = [];
      this.visibleModeratorsToolset = false;
      this.visibleMembersToolset = false;
      this.visibleConnection = false;
      this.hasCallProcess = false;
      this.streamConfigAllowDonations = false;
      this.clearAllConnects();
    },
    updateProfiles(list) {
      list.forEach((p) => {
        if(!mappingManager.hasConnectionId(p.connectionId)) {
          console.log('MappingManager. Add new record [' + p.connectionId + '] for user: ', p.profile.id) ;
          mappingManager.addMapping(p.connectionId, p.profile.id);
        }
      });

      //Kill video track from render
      if(this.videoMixerRenderer) {
        const ids = list.map(item => item.connectionId);
        this.videoMixerRenderer.removeTracksExcept(ids);
      }
    },
    currentSpeaker(list) { 
      if(this.streamSharing) {
        this.videoMixerRenderer.currentSpeaker(list);
      }
    },
    sendHubSignal (candidate, partnerClientId) {
      console.log('sendHubSignal: partner: ' + partnerClientId + ', candidate: ', candidate);
      wsconn.invoke('sendSignal', partnerClientId, candidate).catch(this.logError);
    },
    sendMessage(form) {
      var isGuest = false;
      wsconn.invoke("SendMessage", form.message, isGuest).catch((error) => {
        console.error(error);
      });      
    },
    getLinkifiedPinnedMessage() {
      return linkify(this.pinnedMessage.message.value);
    },
    chatOpen(value) {
      if(value)
        this.newMessageCounter = 0;
    },

    //Recorder
    recorderInterceptor() {
      this.ac = new AudioContext();
      this.destination = new MediaStreamAudioDestinationNode(this.ac);

      this.groupStream.getAudioTracks().forEach((track) => {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(track);
          const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(this.ac, { mediaStream: mediaStream });
          mediaStreamAudioSourceNode.connect(this.destination);
      });

      var merged = this.destination.stream;
      this.$refs.postRecorder.createMediaRecorder(merged);
    },
    recorderModeChanged(state) {
      this.recorderState = state;
      if(wsconn.state === 'Connected') {
        if(state === 'on') {
          wsconn.invoke('RecStart').catch(this.logError);
        }
        else {
          wsconn.invoke('RecStop').catch(this.logError);
        }
      }
    },
    speakerModeChanged(value) {
      if(this.visibleConnection && this.speakersCounter > 1 && wsconn.state === 'Connected')
        wsconn.invoke('ActiveSpeaker', value).catch(this.logError);
    },
    speakerMicStateChanged(value) {
      if(this.visibleConnection && wsconn.state === 'Connected')
        wsconn.invoke('MicState', value).catch(this.logError);
    },
    speakerCamStateChanged(value) {
      if(this.visibleConnection && wsconn.state === 'Connected')
      wsconn.invoke('CamState', value).catch(this.logError);
    },

    //Create post
    sendCreatePostForm() {
      this.buttonCreateDisabled = true;
      this.buttonCreateText = 'Uploading...';

      //blob
      var blob = null;
      var duration = 0;
      if (this.$refs.postRecorder != null && this.$refs.postRecorder.resultBlob != null) {
        blob = this.$refs.postRecorder.resultBlob;
        duration = this.$refs.postRecorder.audioDuration;
      }

      console.log("Send Create post form...");
      console.log("Duration: ", duration);
      console.log("BLOB: ", blob); 

      if(duration > 0 && blob !== null) {
        var post = {
          type: 3, //LIVE
          duration: duration,
          title: this.title,
          message: this.message,
          embed: null,
          isIgnored: false,
        };
        this.$refs.pnlUploader.post(blob, this.previewImage, post);
      } else {
        this.uploaderPostFailed();
      }

    },
    uploaderPostSuccess(id) {
      console.log("Post has been created: ", id);
      this.uploadReset();
      this.title = '';
      this.message = '';
      this.$refs.postRecorder.soundDelete();

      wsconn.invoke('PostCreated', id)
        .then(() => {})
        .catch(this.logError);
    },
    uploaderPostFailed() {
      this.uploadReset();
      console.log("Post has been failed.");
    },
    uploaderUploadSuccess() {
      console.log("Upload has been completed.");
    },
    uploaderUploadFailed() {
      this.uploadReset();
      console.log("Upload has been failed.");
    },
    uploadReset() {
      this.buttonCreateDisabled = false;
      this.buttonCreateText = 'Upload';
    },

    loadingEpisode() {
      axios.get('/api/episode/simple/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.episode = response.data.episode;
        })
        .catch(function () {
        });
    },

    //Stream sharing
    streamStartPublish() {
      this.streamSharing = true;
      this.streamSharingId = this.idSessionGroup;
      this.streamSharingUrl = process.env.VUE_APP_HOST_URL + 'stream/' + this.episode.id + '/' + this.streamSharingId;
      this.streamSharingWhip = process.env.VUE_APP_MEDIAMTX_URL + this.streamSharingId + '/whip';
      console.log('Stream sharing URL', this.streamSharingUrl);
      console.log('Stream WHIP URL', this.streamSharingWhip);
      
      //Render
      this.videoMixerRenderer = new VideoMixer(false);
      this.groupStream.getVideoTracks().forEach((track) => {
        var connectionId = connectionManager.getConnectionIdByTrack(track);
        var userId = mappingManager.getUserIdByConnectionId(connectionId);
        this.videoMixerRenderer.addTrack(connectionId, userId, track);
      });

      var config = {
        whipUrl: this.streamSharingWhip,
        videoRequired: true
      };
      this.streamSharingPublisher = new streamPublisher(config);
      this.streamSharingPublisher.setStream(this.groupStream, this.videoMixerRenderer.getMixedVideoTrack());
      this.streamSharingPublisher.setStream(this.groupStream, this.videoMixerRenderer.getMixedVideoTrack());
      this.streamSharingPublisher.publish();

      wsconn.invoke('PublishStream', this.streamSharingUrl)
          .then(() => {})
          .catch(() => {});
    },
    streamStopPublish() {
      this.streamSharing = false;
      this.streamSharingPublisher.stop();
      this.videoMixerRenderer.stopRendering();
      this.videoMixerRenderer.clean();

      wsconn.invoke('StopStream')
          .then(() => {})
          .catch(() => {});
    },
    streamAllowChat() {
      console.log('Allow chat: ', this.streamConfigAllowChat);
      var value = this.streamConfigAllowChat ? '1' : '0';
      wsconn.invoke('Config', 'allow_guest_chat=' + value)
          .then(() => {})
          .catch(() => {});
    },
    streamAllowDonations() {
      console.log('Allow donations: ', this.streamConfigAllowDonations);
      var value = this.streamConfigAllowDonations ? '1' : '0';
      wsconn.invoke('Config', 'allow_donations=' + value)
          .then(() => {})
          .catch(() => {});
    },
    copyStreamLink() {
      try {
        write(this.streamSharingUrl);
      }
      catch(e) {
        console.error(e);
      }
    },
    profileAction(id, status) {
      console.log("Chat/Profile action: ", id, status);
      wsconn.invoke('Config', 'profile_action_' + status + '=' + id)
          .then(() => {})
          .catch(() => {});
    },

    //Common
    sendSystemMessageToChat(message, attach = false) {
      var time = new Date().toLocaleTimeString();
        var m = {
          align: 'left',
          name: '',
          image: '',
          message: message,
          time: time,
          system: true,
        }
        this.$refs.pnlMessages.addMessage(m, attach, true);
    },
    logError(e) {
      console.error("ERROR wsconn:", e);
    },

    //Debug
    debugShowConnectionManager() {
      console.log(connectionManager.getAllTracks());
    },
    debugShowConnections() {
      console.log(this.connections);
    },
    debugShowGroupStream() {
      console.log("Group stream: ", this.groupStream);
      console.log("All tracks: ", this.groupStream.getTracks());
      console.log("Audio tracks: ", this.groupStream.getAudioTracks());
      console.log("Video tracks: ", this.groupStream.getVideoTracks());
    },
  },
  mounted() {
    this.loadingEpisode();
    this.getConnectionConfig();
  },
  computed: {
    getSessionId() {
      if(this.idSessionGroup)  
        return this.idSessionGroup;
      else if (this.$route.params.session)
        return this.$route.params.session;
      else
        return '';
    }
  },
};
</script>

<template>
  <div class="row">

    <div class="col-12">

      <Title :title="episode.title" :short="episode.short" :url="episode.urlAvatar" :is-server-side="isServerSide" :close-button="!visibleConnection"></Title>

      <div class="mb-4">
        <button class="btn btn-primary me-2" @click="createSession" v-if="!visibleConnection && isServerSide" :disabled="!hasMediaAccess" title="Create live-room session">Create</button>
        <button class="btn btn-primary me-2" @click="joinSession" v-if="!visibleConnection && !isServerSide" :disabled="!hasMediaAccess" title="Join to live-room session">Join</button>
        
        <button class="btn btn-primary me-2" @click="stopSession" v-if="visibleModeratorsToolset && isServerSide" :disabled="recorderState==='on' || hasRecord" title="Stop live-room session">Stop</button>
        
        <button class="btn btn-primary" @click="callToModerator" v-if="visibleMembersToolset && !isServerSide && false" :disabled="hasCallProcess">Call</button>
        <button class="btn btn-primary me-2" @click="hangupCall" v-if="visibleMembersToolset && !isServerSide" :disabled="!hasCallProcess">Hang-up</button>
        
        <button class="btn btn-primary me-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseControlPanel" aria-expanded="false" aria-controls="collapseControlPanel" v-if="visibleModeratorsToolset">
          <i class="ri-arrow-up-down-line"></i>
        </button>
        
        <Recorder ref="postRecorder" @recorder-mode="recorderModeChanged" @prerec-click="recorderInterceptor" @stream-ready="readyStreamAccess" @record-ready="readyRecordFile" @speaker-mode="speakerModeChanged" @speaker-micstate="speakerMicStateChanged" @speaker-camstate="speakerCamStateChanged" :buttonRecVisible="visibleModeratorsToolset" :buttonSourceVisible="!visibleConnection" :buttonMuteVisible="true" trimmerBackModal=".recorderModal2" mode="live"></Recorder>
        
        <b-button variant="primary" class="btn-label ms-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasChatMessages" aria-controls="offcanvasChatMessages">
          <i class="ri-chat-1-line label-icon align-middle fs-16 me-2"></i>
          Chat
          <span v-if="newMessageCounter > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{ newMessageCounter }}</span>
        </b-button>

        <b-button variant="primary" class="btn-label ms-2" v-if="listenersCounter > 0">
          <i class="ri-broadcast-line label-icon align-middle fs-16 me-2"></i> {{ listenersCounter }}
        </b-button>
      </div>

      <Profiles ref="pnlProfiles"></Profiles>

      <div class="mt-2" v-if="pinnedMessage !== null">
        <h5 class="card-title mb-1">
          <i class="ri-pushpin-line"></i>
          <button type="button" @click="profileAction('', 'unpin')" class="btn-close float-end fs-11" v-if="isServerSide"></button>
        </h5>
        <p class="card-text" v-html="getLinkifiedPinnedMessage()"></p>
      </div>
      
      <div class="collapse show" id="collapseControlPanel">

        <div v-if="debugMode" class="card card-body">
          <h3>DEBUG</h3>
          <button @click="debugShowConnectionManager">Connection Manager</button>
          <button @click="debugShowConnections">Connections</button>
          <button @click="debugShowGroupStream">GroupStream</button>
        </div>

        <div class="card shadow-none mt-3 mb-0" v-if="hasRecord">
          <div class="card-header ps-0 pt-0">
            <h2 class="card-title mb-0 flex-grow-1">Save new post</h2>
          </div>
          <div class="card-body p-0 mt-2">

            <div class="row">
              <div class="col-12">
                <div>
                  <label for="title" class="form-label">Title</label>
                  <input type="text" class="form-control" id="title" placeholder="Your title" v-model="title" v-on:keydown.enter.prevent="preventEnterKey">
                </div>
              </div>
              <div class="col-12 mt-3">
                <div>
                  <label for="message" class="form-label">Comment</label>
                  <textarea id="message" class="form-control" rows="2" placeholder="Your comment" v-model="message"></textarea>
                </div>
              </div>
              <div class="col-12 mt-3">
                <Uploader ref="pnlUploader" @post-success="uploaderPostSuccess" @post-error="uploaderPostFailed" @upload-success="uploaderUploadSuccess" @upload-error="uploaderUploadFailed" />
                <button type="button" @click="sendCreatePostForm" class="btn btn-primary" v-bind:disabled="buttonCreateDisabled">{{ buttonCreateText }}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="card shadow-none m-0 mt-3" v-if="visibleConnection && isServerSide">
          <div class="card-header ps-0 pt-0">
            <h2 class="card-title mb-0 flex-grow-1">Share stream</h2>
          </div>
          <div class="card-body ps-lg-0 pe-0">
            <button class="btn btn-primary me-2" @click="streamStartPublish" v-if="!streamSharing">Create stream link</button>
            <button class="btn btn-primary" @click="streamStopPublish" v-if="streamSharing">Stop stream sharing</button>
            <div class="mt-2" v-if="streamSharing">
              <div class="input-group">
                <input type="text" class="form-control readonly" readonly="readonly" placeholder="" aria-label="" aria-describedby="btcShareLinkCopy" :value="streamSharingUrl">
                <button class="btn btn-primary" type="button" id="btcShareLinkCopy" @click="copyStreamLink">
                  <span class="ri-file-copy-line"></span>
                </button>
              </div>
              <div class="form-check mt-2 mb-2">
                <input class="form-check-input" type="checkbox" id="chkAllowChat" v-model="streamConfigAllowChat" @change="streamAllowChat">
                <label class="form-check-label" for="chkAllowChat">
                  Allow chat
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chkAllowDonations" v-model="streamConfigAllowDonations" @change="streamAllowDonations">
                <label class="form-check-label" for="chkAllowDonations">
                  Allow donations
                </label>
              </div>
            </div>
          </div>
        </div>

        <Donate ref="pnlDonations" v-if="streamConfigAllowDonations && isServerSide" :session="this.idSessionGroup" :episode="this.episode.id"></Donate>

      </div>

    </div>
    
  </div>

  <Messages ref="pnlMessages" :imModerator="isServerSide" @profile-click="profileAction" @chat-open="chatOpen" @send-message="sendMessage" :donate-allows="streamConfigAllowDonations" :chat-allows="streamConfigAllowChat" :session="getSessionId" :episode="this.$route.params.id"></Messages>
</template>
