<script>
import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions'

import lamejs from 'lamejs'
import MPEGMode from 'lamejs/src/js/MPEGMode';
import Lame from 'lamejs/src/js/Lame';
import BitStream from 'lamejs/src/js/BitStream';

window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;

import Slider from "@vueform/slider";

export default {
    emits: ["updateAudio"],
    name: "Trimmer",
    data() {
        return {
            audioPlayerVisible: false,
            buttonUpdateDisabled: true,
            buttonUndoDisabled: true,
            buttonUpdateText: 'Update',
            classPlay: 'ri-play-fill',
            classPlayPause: 'ri-pause-fill',
            audioPlayerClass: 'ri-play-fill',
            waveSurfer: null,
            lastRegion: null,
            loading: false,
            resultTrimmedBlob: null,
            resultTrimmedBlobUrl: null,
            mp3Encoder: null,
            buffer: [],
            maxSamples: 1152,
            sampleBlockSize: 1152,
            zoomLevel: 100,
        }
    },
    props: {
        modalClassToBack: {
            type: String,
            default: function() {
                return '';
            }
        },
        modalFormId: {
            type: String,
            default: function() {
                return 'trimmerModal1';
            }
        },
        resultBlob: {
            type: Object,
            default: function() {
                return null;
            }
        },
        resultBlobUrl: {
            type: String,
            default: function() {
                return '';
            }
        },
    },
    created: function () {
    },
    components: {
        Slider,
    },
    mounted() {
    },
    methods: {
        updateMainTrack() {
            var model = {
                blob: this.resultTrimmedBlob,
                url: this.resultTrimmedBlobUrl,
            };
            this.$emit('updateAudio', model);
            this.$refs.butClose.click();
        },
        waveSurfDestroy() {
            if(this.lastRegion) {
                this.lastRegion.remove();
                this.lastRegion = null;
            }
            if(this.waveSurfer) {
                this.waveSurfer.destroy();
                this.waveSurfer = null;
            }
        },
        modalShown() {
            console.log("Modal to back:", this.modalClassToBack);
            var currentUrl;
            if(this.resultTrimmedBlobUrl !== null)
                currentUrl =  this.resultTrimmedBlobUrl;
            else
                currentUrl = this.resultBlobUrl;
            this.waveSurfDestroy();
            this.waveSurfer = WaveSurfer.create({
                container: '#waveView_' + this.modalFormId,
                waveColor: '#4F4A85',
                progressColor: '#383351',
                url: currentUrl,
                height: 240,
                minPxPerSec: 100,
                hideScrollbar: false,
                autoScroll: true,
            });
            this.waveSurfer.on('ready', this.waveReady);
            this.waveSurfer.on('finish', this.waveFinish);

            var wsRegions = this.waveSurfer.registerPlugin(RegionsPlugin.create());
            wsRegions.enableDragSelection({ color: 'rgba(255, 0, 0, 0.1)', loop: true });
            wsRegions.on('region-created', this.waveRegionCreated); 
            wsRegions.on('region-updated', this.waveRegionUpdated); 
            wsRegions.on('region-double-clicked', this.waveRegionDblClick); 
        },
        modalHidden() {
            this.resultTrimmedBlob = null;
            this.resultTrimmedBlobUrl = null;
            this.buttonUpdateDisabled = true;
            this.buttonUndoDisabled = true;
            this.waveSurfDestroy();
        },
        waveReady() {
            this.audioPlayerVisible = true;
            console.log("Audio is ready");
        },
        waveFinish() {
            this.audioPlayerClass = this.classPlay;
        },
        waveRegionCreated(region) {
            if(this.lastRegion) {
                this.lastRegion.remove();
            }
            this.lastRegion = region;
        },
        waveRegionUpdated(region) {
            console.log("Updated region:", region);
        },
        waveRegionDblClick(region) {
            this.audioPlayerClass = this.classPlayPause;
            region.play();
        },
        zoomWavesurf(e) {
            this.waveSurfer.zoom(e);
        },
        soundPlay() {
            if (this.audioPlayerClass === this.classPlay) {
                this.audioPlayerClass = this.classPlayPause;
                this.waveSurfer.play();
            } else {
                this.audioPlayerClass = this.classPlay;
                this.waveSurfer.pause();
            }
        },
        soundOriginal() {
            this.resultTrimmedBlob = null;
            this.resultTrimmedBlobUrl = null;
            this.audioUndoDisabled = true;
            this.buttonUpdateDisabled = true;
            this.modalShown();
        },
        soundCrop() {
            console.log("Start cropping...");
            this.buffer = [];
            this.loading = true;

            //Read the original Audio
            var arrBuffer;
            var currentBlob;
            if(this.resultTrimmedBlob !== null)
                currentBlob =  this.resultTrimmedBlob;
            else
                currentBlob = this.resultBlob;
            this.readAudio(currentBlob)
            .then((results) => {
                arrBuffer = results.result;
                console.log("Array buffer", arrBuffer);

                var acoptions = {
                    sampleRate: 44100,
                };

                //Audio buffer
                var ac = new AudioContext(acoptions)
                    .decodeAudioData(arrBuffer)
                    .then(this.decodedAudio);
                console.log("AudioContext", ac);
            });
        },
        decodedAudio(res) {
            var audioBuffer = res;
            console.log("Audio buffer", audioBuffer);

            var start = this.lastRegion.start;
            var end = this.lastRegion.end;
            var totalAudioDuration = audioBuffer.duration;
            var startPoint = Math.floor((start*audioBuffer.length)/totalAudioDuration);
            var endPoint = Math.ceil((end*audioBuffer.length)/totalAudioDuration);

            var audioLength = audioBuffer.length - (endPoint - startPoint);
            console.log("Start, StartPoint:", start, startPoint);
            console.log("End, EndPoint:", end, endPoint);
            console.log("Total duration:", totalAudioDuration);
            console.log("Number of channels:", audioBuffer.numberOfChannels);
            console.log("Audio length:", audioLength);
            console.log("Audio sample rate:", audioBuffer.sampleRate);
            
            var trimmedAudio = new AudioContext().createBuffer(
                audioBuffer.numberOfChannels,
                audioLength,
                audioBuffer.sampleRate
            );
            console.log("TrimmedAudio 1", trimmedAudio);
            
            //First part
            for(var i1=0; i1 < audioBuffer.numberOfChannels; i1++){
                trimmedAudio.copyToChannel(audioBuffer.getChannelData(i1).slice(0, startPoint), i1);
            }
            
            var offset = startPoint;
            console.log("Offset:", offset);

            //Second part
            for(var i2=0;i2<audioBuffer.numberOfChannels;i2++){
                trimmedAudio.copyToChannel(audioBuffer.getChannelData(i2).slice(endPoint), i2, offset);
            }
            console.log("TrimmedAudio 2", trimmedAudio);

            //ENCODE
            var channelsMap = Array.apply(null,{length: trimmedAudio.numberOfChannels})
            .map(function(currentElement, index) {
                return trimmedAudio.getChannelData(index);
            });
            console.log("Channels map:", channelsMap);
            console.log("Trimmed audio length:", trimmedAudio.length);
            console.log("Trimmed audio rate:", trimmedAudio.sampleRate);
            var audioData = {
                channels: channelsMap,
                sampleRate: trimmedAudio.sampleRate,
                length: trimmedAudio.length,
            }
            console.log("Audio data: ", audioData);

            console.log("Encode start...");
            this.encode(audioData)
                .then((res) => {
                    console.log("Success", res);

                    var blob = new Blob(res, {type: 'audio/mp3'});
                    var processedUrl = URL.createObjectURL(blob);
                    console.log("BLOB Url", processedUrl);
                    console.log("---------------------");
                    this.resultTrimmedBlob = blob;
                    this.resultTrimmedBlobUrl = processedUrl;
                    this.buttonUpdateDisabled = false;
                    this.audioUndoDisabled = false;
                    this.modalShown();
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async readAudio(audioFile) {
            console.log("AudioFile:", audioFile);
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(audioFile);

                reader.onload = function() {
                    console.log("Audio Loaded");
                    resolve(reader);
                }

                reader.onerror = function(error){
                    console.log("Error while reading audio");
                    reject(error);
                }

                reader.onabort = function(abort){
                    console.log("Aborted", abort);
                    reject(abort);
                }
            })
        },
        async encode (audioData) {
            if (audioData.channels.length == 1) {
                this.mp3Encoder = new lamejs.Mp3Encoder(1, audioData.sampleRate, 128);
                return new Promise((resolve, reject) => {
                    console.log("Reject 1:", reject);

                    var arrayBuffer = audioData.channels[0];
                    var samples = new Int16Array(arrayBuffer.length);
                    this.floatTo16BitPCM(arrayBuffer, samples);

                    var mp3buf = this.mp3Encoder.encodeBuffer(samples);
                    this.appendToBuffer(mp3buf);

                    this.finish();
                    resolve(this.buffer);
                });
            }

            if (audioData.channels.length == 2) {
                this.mp3Encoder = new lamejs.Mp3Encoder(2, audioData.sampleRate, 128);
                return new Promise((resolve, reject) => {
                    console.log("Reject 2:", reject);

                    var right = new Int16Array(audioData.channels[0].length);
                    this.floatTo16BitPCM(audioData.channels[0], right);

                    var left = new Int16Array(audioData.channels[1].length);
                    this.floatTo16BitPCM(audioData.channels[1], left);

                    for (var i = 0; i < audioData.channels[0].length; i += this.sampleBlockSize) {
                        var leftChunk = left.subarray(i, i + this.sampleBlockSize);
                        var rightChunk = right.subarray(i, i + this.sampleBlockSize);
                        var mp3buf = this.mp3Encoder.encodeBuffer(leftChunk, rightChunk);
                        if (mp3buf.length > 0) {
                            this.buffer.push(mp3buf);
                        }
                    }
                    this.finish();
                    resolve(this.buffer);
                });
            }
        },
        appendToBuffer(mp3Buf) {
            this.buffer.push(new Int8Array(mp3Buf));
        },
        floatTo16BitPCM (input, output) {
            for (var i = 0; i < input.length; i++) {
                var s = Math.max(-1, Math.min(1, input[i]));
                output[i] = (s < 0 ? s * 0x8000 : s * 0x7FFF);
            }
        },
        finish () {
            this.appendToBuffer(this.mp3Encoder.flush());
            console.log('done encoding, size=', this.buffer.length);
        }
    }
}
</script>

<template>
    <b-modal @shown="modalShown" @hidden="modalHidden" hide-footer :hide-header-close="true" title="Trimmer" class="v-modal-custom zoomIn" :class="modalFormId" size="xl" no-close-on-backdrop>
        <div class="mb-4">
            <button type="button" class="btn btn-primary ms-2" v-if="audioPlayerVisible" @click="soundPlay">
                <span v-bind:class="audioPlayerClass"></span>
            </button>
            <button type="button" class="btn btn-danger ms-2" v-if="audioPlayerVisible" :disabled="lastRegion === null || loading" @click="soundCrop">
                <i class="ri-scissors-2-line"></i>
            </button>
            <button type="button" class="btn btn-danger ms-2" v-if="audioPlayerVisible" :disabled="buttonUndoDisabled" @click="soundOriginal">
                <i class="ri-arrow-go-back-line"></i>
            </button>
            <div class="d-inline-block ms-3 w-240">
                <Slider v-model="zoomLevel" v-bind:tooltips="false" @slide="zoomWavesurf" :min="10" :max="1000" />
            </div>
        </div>
        <div :id="'waveView_' + modalFormId" class="ms-2 me-2 mb-3">
        </div>
        <div class="modal-footer v-modal-footer">
            <div class="spinner-border text-primary ms-2 mt-1" role="status" v-if="loading">
                <span class="sr-only">Loading...</span>
            </div>
            <button type="button" @click="updateMainTrack" class="btn btn-primary" v-bind:disabled="buttonUpdateDisabled">{{ buttonUpdateText }}</button>
            <a ref="butClose" class="btn btn-light fw-medium" :data-bs-target="modalClassToBack" data-bs-toggle="modal" data-bs-dismiss="modal">Close</a>
        </div>
    </b-modal>
</template>
