const connections = {};

function addTrack(connectionId, track) {
    if (!connections[connectionId]) {
        connections[connectionId] = [];
    }
    
    const trackExists = connections[connectionId].some((existingTrack) => existingTrack.track.id === track.id);
    if (!trackExists) {
        connections[connectionId].push({
            cid: connectionId,
            track: track,
            kind: track.kind,
        });
        console.log('ConnectionManager. Pushed new track: ', connections);
    }
}

function removeTrack(connectionId, trackToRemove) {
    if (connections[connectionId]) {
        connections[connectionId] = connections[connectionId].filter((track) => track.track.id !== trackToRemove.id);
        console.log('ConnectionManager. Removed track: ', connections);
        if (connections[connectionId].length === 0) {
            delete connections[connectionId];
            console.log('ConnectionManager. Deleted all array in connection: ', connections);
        }
    }
}

function hasConnectionId(connectionId) {
    return !!connections[connectionId];
}

function getTracksByConnectionId(connectionId) {
    return connections[connectionId] || [];
}

function getAllTracks() {
    return connections;
}

function getConnectionIdByTrack(track) {
    if(track) {
        for (const connectionId in connections) {
            const tracks = connections[connectionId];
            const trackExists = tracks.some((existingTrack) => existingTrack.track.id === track.id);
            //const trackExists = tracks.some((existingTrack) => existingTrack.track === track);

            if (trackExists) {
                return connectionId;
            }
        }
    }
    return '';
}

function removeAllTracksByConnectionId(connectionId) {
    if (connections[connectionId]) {
    delete connections[connectionId];
    }
}

function clearTracks() {
    for (const connectionId in connections) {
    delete connections[connectionId];
    }
}
  
export const connectionManager = {
    connections,
    addTrack,
    removeTrack,
    getTracksByConnectionId,
    hasConnectionId,
    getConnectionIdByTrack,
    removeAllTracksByConnectionId,
    clearTracks,
    getAllTracks,
};

export default connectionManager;